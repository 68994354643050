import React, { useState } from 'react';
import { FaFacebookMessenger, FaMailBulk, FaPhone, FaUser, FaWhatsapp } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import './contact.css';
const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        console.log(name + " " + email + " " + message);
        e.preventDefault();
        emailjs.send("service_wa9hd1s", "template_hjkon8d", {
            name: name,
            message: message,
            phone: phone,
            email: email,
        });

        e.target.reset();
    };

    return (
        <div className="contact" id="contact">
            <div className="cocontent">
                <h1>
                    Get in touch!
                </h1>
                <div className="pinss">
                    <div className="method">
                        <FaFacebookMessenger />
                        <span>+265 888 85 94 79 </span>
                        <a href='https://wa.me'>Say Hi</a>
                    </div>
                    <div className="method">
                        <FaWhatsapp />
                        <span>+265 888 85 94 79</span>
                        <a href='https://api.whatsapp.com/send?phone=265888859479&text=Hello,%20more%20information!'>Say Hi <span>👋</span></a>
                    </div>
                </div>
                <form onSubmit={sendEmail}>
                    <div className="form" >
                        <div className="one">
                            <label>Name</label>
                            <div className="inn"><FaUser />
                                <input
                                    type="text"
                                    name="name"
                                    placeholder='Enter your Name'

                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <label>Email</label>
                            <div className="inn"><FaMailBulk />
                                <input
                                    type="text"
                                    name="email"
                                    placeholder='Enter your Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                /></div>
                            <label>Phone</label>
                            <div className="inn"><FaPhone />
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder='Enter your Phone Number'
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="two">
                            <label htmlFor="">Message</label>
                            <textarea
                                name="message" placeholder='Write your Message' id="" cols="10" rows="10"
                                onChange={(e) => setMessage(e.target.value)} ></textarea>
                        </div>
                    </div>
                    <button type='submit'>Send Message <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><title>Send Mail</title><path id="Send_Mail_2" data-name="Send Mail 2" d="M23.75,5H7.25A1.25,1.25,0,0,0,6,6.25v13.5A1.25,1.25,0,0,0,7.25,21h16.5A1.25,1.25,0,0,0,25,19.75V6.25A1.25,1.25,0,0,0,23.75,5ZM7.25,6h16.5a.25.25,0,0,1,.25.25h0l-8.5,8L7,6.28H7A.25.25,0,0,1,7.22,6Zm16.5,14H7.25A.25.25,0,0,1,7,19.75V7.66l8.16,7.7a.5.5,0,0,0,.69,0L24,7.66V19.75A.25.25,0,0,1,23.75,20ZM5,11H0V10H5Zm0,3H1V13H5Zm0,3H2V16H5Z" fill="#faebd7" /></svg></button>
                </form>



            </div>
        </div>
    )
}

export default Contact;