import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import './about.css';
import last from '../../assets/images/last.jpg'
const About = () => {
    const aRef = useRef(null);
    useEffect(() => {

        const observer1 = new IntersectionObserver((entries) => {

            entries.forEach((entry) => {
                if (entry.isIntersecting) {


                    gsap.to(aRef.current, { duration: 1, opacity: 1, y: 0 });
                    gsap.fromTo(".pictureframe", { width: 20, height: 20 }, {

                        width: 300,
                        height: 300,
                        duration: 1,
                        ease: "power2.out"
                    });
                    gsap.fromTo(".firstrect", { width: 20, height: 20 }, {

                        width: 300,
                        height: 150,
                        duration: 1,
                        ease: "power2.out"
                    });
                    gsap.fromTo(".secondrect", { width: 20, height: 20 }, {

                        width: 300,
                        height: 150,
                        duration: 1,
                        ease: "power2.out"
                    });
                } else {
                    gsap.to(aRef.current, { duration: 1, opacity: 0, y: 0 });
                    gsap.fromTo(".pictureframe", { width: 300, height: 300 }, {

                        width: 20,
                        height: 20,
                        duration: 1,
                        ease: "power2.out"
                    });
                    gsap.fromTo(".firstrect", { width: 300, height: 150 }, {

                        width: 20,
                        height: 20,
                        duration: 1,
                        ease: "power2.out"
                    });
                    gsap.fromTo(".secondrect", { width: 300, height: 150 }, {

                        width: 20,
                        height: 20,
                        duration: 1,
                        ease: "power2.out"
                    });

                }
            });
        });
        observer1.observe(aRef.current);
        return () => {
            // eslint-disable-next-line
            observer1.unobserve(aRef.current);
        };

    });

    return (
        <div className="about" id="about" data-scroll-section >
            <div className="firstdiv">
                <div className="pictureframe">

                </div>
                <div className="picture">
                    {/* <img src={last} alt="meh" /> */}
                    <img src="https://ik.imagekit.io/kazama/Becky/jj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676713842350" alt="Becky" data-scroll />
                </div>

                <div className="secondrect"></div>
                <div className="firstrect"></div>


            </div>
            <div className="seconddiv">
                <div className="aboutcontent" ref={aRef}>
                    <h1>About Me</h1>
                    <p>I am an experienced creative entrepreneur who is always looking for new challenges
                        and opportunities to serve. I am a creative thinker, planner, and doer. I am confident,
                        friendly, and driven. I firmly believe that with confidence, you can achieve anything
                        in this world. As a passionate creative with a strong sense of wander and an appreciation
                        for all things beautiful, I strongly believe that every person has a sleeping giant
                        waiting to be awakened, and I am happy to help unleash that power. As a lover of life
                        and people, I bring a light to any room and offer a reminder of intellect with a
                        transformative attitude towards positive change and effective influence..</p>
                </div>

            </div>
        </div>
    );
}

export default About;