import React from "react";
import "./footer.css"; // import CSS file for styling
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram, FaPhone } from "react-icons/fa";
function Footer() {
    return (
        <div className="foot">
            <div className="footer">
                <div className="company-name hehe">
                    <h3>REBECCAH KAZINGATCHIRE</h3>

                </div>
                <div className="follow-us hehe">
                    <h3>Follow Me</h3>
                    <ul>
                        <li className='foosocials'><a href='https://facebook.com/rebeccah.carzinga'><FaFacebook /></a></li>
                        <li className='foosocials'><FaTwitter /></li>
                        <li className='foosocials'><a href='https://www.linkedin.com/in/rebeccah-kazingatchire-5b5a741a2/'><FaLinkedin /></a></li>
                        <li className='foosocials'><a href='https://www.instagram.com/rkazingatchire/'><FaInstagram /></a></li>
                    </ul>
                </div>
                <div className="call-us hehe">
                    <h3>Call Me</h3>
                    <p><FaPhone /><a href="tel:+265-888-85-94-79"> +265 888 85 94 79</a></p>
                </div>

            </div>
            <span>Designed and Developed by HopeDexter</span>
        </div>
    );
}

export default Footer;
