import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './hero.css';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';


const Hero = () => {
    const heroRef = useRef(null);
    useEffect(() => {

        const observer = new IntersectionObserver((entries) => {

            // entries.forEach((entry) => {
            //     if (entry.isIntersecting) {
            //         gsap.to(heroRef.current, { duration: 5, opacity: 1, y: 0 });
            //     } else {
            //         gsap.to(heroRef.current, { duration: 2, opacity: 0, y: 0 });
            //     }
            // });
            gsap.fromTo(".leftside", { x: -100, opacity: 0 }, {
                x: 0,
                opacity: 1,
                duration: 1,
                ease: "power2.out"
            });

            gsap.to('.rightside', { y: 0, opacity: 1, duration: 2, ease: 'power2.out' });
            gsap.to(".arrow-down", {
                y: 10,
                repeat: -1,
                ease: "power1.inOut",
                yoyo: true
            });

        });
        observer.observe(heroRef.current);


    }, []);

    return (
        <div className='hero' data-scroll-section >
            <div className='leftleft'>
                <div className="leftside">
                    <ul>
                        <li className='socials'><a href='https://facebook.com/rebeccah.carzinga'><FaFacebook /></a></li>
                        <li className='socials'><FaTwitter /></li>
                        <li className='socials'><a href='https://www.linkedin.com/in/rebeccah-kazingatchire-5b5a741a2/'><FaLinkedin /></a></li>
                        <li className='socials'><a href='https://www.instagram.com/rkazingatchire/'><FaInstagram /></a></li>
                    </ul>
                    <div className="overlay">
                        <img src="https://ik.imagekit.io/kazama/Becky/name.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676713952102" alt="Rabecca" />
                    </div>
                </div>
            </div>

            <div className="rightside" ref={heroRef}>
                <div className="contents">
                    <h1>Rebeccah Kazingatchire.</h1>
                    <div className="titles">
                        <h4>CREATIVE | MULTIPOTENTIALITE | ENTREPRENUER</h4>
                    </div>
                    <p>
                        Welcome to my virtual catalogue of thoughts, experiences and advocancy
                    </p>
                    <a href="#contact" className="cta">
                        <span>Connect</span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                            <path d="M1,5 L11,5"></path>
                            <polyline points="8 1 12 5 8 9"></polyline>
                        </svg>
                    </a>
                </div>
            </div>

            <div className='scrolll'>
                <div className="scroll-container">
                    <span className="arrow-down">↓</span>
                    <span className="scroll-text">SCROLL</span>
                </div>
            </div>

            <div className='shape shape1'></div>
            <div className='shape shape2'></div>
            <div className='shape shape3'></div>
        </div >
    );
};

export default Hero;
