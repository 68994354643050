import React from "react";
import "./style.css"
const Strat = () => {
    return (
        <div className="start-container">
            <div className="strat">
                <div className="words">
                    <h2 className="sap">
                        Content Marketer & Brand Strategist
                    </h2>

                    <p className="sap">
                        I specialize in helping startups build long-lasting, sustainable brands. My focus is on creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience. I create content that incorporates both audio and visual elements, appealing to multiple senses and helping viewers to gain a clear understanding of how things work within your company.
                    </p>
                </div>
                <div className="imagess">
                    <img src="https://ik.imagekit.io/kazama/Becky/becky.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676813815516" alt="Working as a brand strategist" />
                </div>
                <div className="extra">

                </div>
            </div>
        </div>
    )
}

export default Strat;