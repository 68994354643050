// WhatIDo.js
import React from 'react';
import './style.css';
import { useInView } from 'react-intersection-observer';

const Gallery = () => {

  // const fadeIn = () => {
  //   console.log("in view");
  //   gsap.to(".cards-container", {
  //     opacity: 1,
  //     duration: 1,
  //     y: 1,
  //     ease: "power4.out",
  //     stagger: {
  //       amount: .3
  //     }
  //   })
  // }
  // const fadeOut = () => {
  //   console.log("out view");
  //   gsap.to(".cards-container", {
  //     opacity: 0,
  //     duration: 1,
  //     y: 200,
  //     ease: "power4.out",

  //   })
  // }
  const { ref } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 0.4,

  });

  // useEffect(() => {
  //   inView ?
  //     fadeIn() :
  //     fadeOut();
  // })
  return (
    <div className='gallery' id="gallery" ref={ref}>
      <h2>Event Planner</h2>
      <p className='gap'>
        I am the founder of Events_Expo, a nationwide event planning and organizing company. We offer comprehensive event production and management services, covering both large and small-scale personal and corporate events, including formal parties, exhibitions, conferences, seminars, and more.

        Our aim is to make event planning easier for you and to create an unforgettable event. We spare no effort in meticulously planning every aspect of your event. We will work with you to design the event according to your direction, theme, and desired needs, from initial planning to post-event strategizing</p>
      <div className="cards-container" >
        <div className="card">
          <img src="https://ik.imagekit.io/kazama/Becky/gift.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676713417264" alt=" for card 1" />
          <h3>Gift Packages</h3>
        </div>
        <div className="card">
          <img src="https://ik.imagekit.io/kazama/Becky/car.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676713803130" alt=" for card 2" />
          <h3>Car Hire</h3>
        </div>
        <div className="card">
          <img src="https://ik.imagekit.io/kazama/Becky/accessories.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676713773950" alt="for card 3" />
          <h3>Accessories Hire</h3>
        </div>
        <div className="card">
          <img src="https://ik.imagekit.io/kazama/Becky/wedding.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676713781731" alt="for card 3" />
          <h3>Wedding Planning</h3>
        </div>
      </div>
    </div>
  )
};

export default Gallery;
