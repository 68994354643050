import React, { useState, useEffect } from "react";
import {  DotLoader } from "react-spinners";
import About from "./Pages/About/about";
import Exp from "./Pages/background/background";
import Hero from "./Pages/Hero/hero";
import Nav from "./Pages/Nav/nav";
import CustomCursor from "./CustomCursor";
import Gallery from "./Pages/Gallery"
import PinedList from "./Pages/Pined";
import Strat from "./Pages/Strat";
import Footer from "./Pages/footer/Footer";
import Contact from "./Pages/contact/Contact";
import Ham from "./Pages/Ham";
import PinnedList from "./Pages/Pinned";
import Shy from "./Pages/Shy";


function App() {
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => {
        setShowWelcome(true);
      }, 1000);
    }, 3000);
  }, []);

  return (
    <>
    <CustomCursor/>
    <div style={{ textAlign: "center" }}>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <p>Becky is Thinking </p> <br/>
          <DotLoader size={30} color={"#123abc"} loading={isLoading} />
        </div>
      ) : (
        <div className="main-container" id="main-container" >
          <Nav/>
           <Ham/> 
          <Hero />
          <About />
         
          
          <Exp />
          <Gallery/>
          <Strat/>
      
          <PinnedList/>
          <PinedList/>
          {/* <Shy/> */}
         <Contact/>
          <Footer/>
          
        </div>
      )}
    </div>
    </>
  );
}

export default App;