import React from 'react';
import "./nav.css";

const Nav = () => {
    // useEffect(() => {
    //     gsap.fromTo(".nav", { y: -100, opacity: 0 }, {
    //         y: 0,
    //         opacity: 1,
    //         duration: 1,
    //         ease: "power2.out"
    //     });
    // }, [])
    return (
        <div className="header" data-scroll-section>
            <nav className='nav'>
                <ul className='logo'>
                    <h1>Becky</h1>
                </ul>
                <ul className='items'>
                    <li className='nav_item'>About</li>
                    <li className='nav_item'>Backgroud</li>
                    <li className='nav_item'>Events Expo</li>
                    <li className='nav_item'>contact</li>
                </ul>
            </nav>
        </div>
    );
}


export default Nav;