import React from "react";
import "./style.css";
const Ham = () => {
    return (
        <div className="ham">
            <input id="hamburger" className="hamburger" type="checkbox" />
            <label className="hamburger" htmlFor="hamburger">
                <i></i>
                <span>
                    <span className="close">close</span>
                    <span className="open">menu</span>
                </span>
            </label>
            <section className="drawer-list">
                <ul>
                    <li><a href="#home">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#back">Background</a></li>
                    <li><a href="#gallery">Events Expo</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </section>
        </div>
    )
}

export default Ham;