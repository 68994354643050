import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './style.css'
gsap.registerPlugin(ScrollTrigger);

function PinedList() {
    const pinedListRef = useRef(null);
    const pinedInnerRef = useRef(null);

    useEffect(() => {
        const pinedList = pinedListRef.current;
        const pinedInner = pinedInnerRef.current;
        const animation = gsap.to(pinedList, {
            x: -pinedList.clientWidth + pinedInner.clientWidth,
            scrollTrigger: {
                trigger: ".pined",
                start: "top top",
                end: `+=${pinedList.clientWidth}`,
                pin: true,
                scrub: 1,
            },
        });
        return () => {
            animation.kill();
        };
    }, []);

    return (
        <div className="pined">
            <h2 className="pap">Modelling</h2>
            <p className='pap'>
                Rebeccah Kazingatchire is a Beauty Queen(World Miss university Africa,2022) and
                a Pageant Coach.She trains Young girls who aspire to be models to build a strong
                Foundation for them to be Supermodels.From etiquette to personal branding,Public
                Speaking and the Cat walk.She believes that Pageantry is a leadership platform
                that helps empower and uplift Women and all the social issues they advocate for.
                She also believes that it helps build social skills and promises social economic
                advocacy in a country with more opportunities to shine.She aspires to shatte
                r glass ceilings and break the stigma that women in pageantry cannot be both beautiful
                and intelligent.She hopes to contribute to the attainment of the Sustainable
                development goals including
                <ul className="goals">
                    <li>Ending Poverty</li>
                    <li>Zero Hunger</li>
                    <li>good health and well-being</li>
                    <li>Gender equality and empowering all women
                        and girls</li>
                    <li>Decent works and economic growth</li>
                </ul></p>
            <div className="pined-inner" ref={pinedInnerRef}>
                <ul className="pined-list" ref={pinedListRef}>
                    <li><img src="https://ik.imagekit.io/kazama/Becky/model.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676813744316" alt=" for card 1" /></li>
                    <li><img src="https://ik.imagekit.io/kazama/Becky/afr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677064904291" alt="World Miss uni"></img></li>
                    <li><img src="https://ik.imagekit.io/kazama/Becky/wmu.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677064904552" alt=" for card 1" /></li>
                    <li><img src="https://ik.imagekit.io/kazama/Becky/fr.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677064905903" alt=" for card 1" /></li>
                </ul>
            </div>
        </div>
    );
}

export default PinedList;
