import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './back.css';
import { FaFile } from 'react-icons/fa';
import CV from "../../assets/docs/becky.pdf"
const Exp = () => {
    const imgRef = useRef(null);
    const secRef = useRef(null);
    useEffect(() => {
        // Register ScrollTrigger with GSAP
        gsap.registerPlugin(ScrollTrigger);

        const pp = gsap.timeline({
            scrollTrigger: {
                trigger: imgRef.current,
                start: "top top",
                end: "20% top",
                scrub: false
            }
        });

        // Create a timeline to animate the image
        const tp = gsap.timeline({
            scrollTrigger: {
                trigger: imgRef.current,
                start: "50% bottom",
                end: "20% top",
                scrub: false
            }
        });

        // Add a clip-path animation to the timeline
        tp.to(imgRef.current, {
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
        });

        pp.to(secRef.current, {
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
        });

    }, []);
    return (
        <div className='backg' id="back">



            <div className="kamanav">
                <h3>ACADEMIC AND PROFFESIONAL BACKGROUND</h3>
            </div>
            <div className='latest'>
                <div className="sect">
                    {/* <div className='rect1'></div> */}
                    <div className="entre" ref={secRef}>
                        <img src="https://ik.imagekit.io/kazama/Becky/entre.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1676713795178" alt="As an entreprenuer" />
                    </div>
                </div>
                <div className="info">
                    <p>
                        I hold a bachelor's degree in social sciences, majoring in Public Administration
                        and minoring in Psychology. My research was based on strategy implementation and
                        startup organizations. I am the founder and managing director of MotoUnandi, an
                        organization that helps build people's self-esteem. I frequently work with
                        community-based and non-governmental organizations, and I have pioneered my
                        own humanitarian projects advocating for education, mental health, and all
                        the social issues affecting young people in the community.
                    </p>
                    <a download="" href={CV} className="btn-slide2">
                        <span className="circle2"><FaFile /></span>
                        <span className="title2">Download CV</span>
                        <span className="title-hover2">Click here</span>
                    </a>

                </div>
                <div className='gara' ref={imgRef}>
                    <img src="https://ik.imagekit.io/kazama/Becky/beck.png?ik-sdk-version=javascript-1.4.3&updatedAt=1676713884202" alt="Gradutaion" />
                </div>
            </div>





        </div>
    )
}

export default Exp;